import * as React from 'react';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {
    Breadcrumbs,
    Checkbox,
    FormControlLabel,
    InputLabel,
    MenuItem,
    NativeSelect,
    Select,
    Tooltip
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import {defer, Link as RouterLink, useLoaderData} from "react-router-dom";
import Container from "@mui/material/Container";
import Requester from "../../../utils/requester";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";


export const timersLoader = async ({params}) => {
    const response = await Requester.get(`timers/get_timers/`)
    const response_data = await response.json()
    return defer(response_data)
}

export default function TimerList() {
    const timers = useLoaderData();
    const {errors, setErrors} = React.useState({})

    return (
        <>
            PUPUPU
        </>
    )
        ;
}
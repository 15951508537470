import React, {useState, useCallback} from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import {ThemeProvider} from "@mui/material/styles";
import {Link} from "react-router-dom";
import theme from "../theme";
import MyCopyright from "../modules/common/copyright";
import {ym_hit} from "../utils/yandex";
import Grid from "@mui/material/Grid";
import {
    Card,
    CardContent,
    CardHeader,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TelegramButton from '../components/TelegramButton';
import { subscriptionPlans } from "../data/subscriptionPlans";

// Определим константу для нового светлого фиолетового цвта
const LIGHT_PURPLE = '#B975FF'; // Более светлый фиолетовый
const LIGHT_PURPLE_HOVER = 'rgba(185, 117, 255, 0.2)'; // Полупрозрачный для hover эффектов

const Hero = () => (
    <Box
        sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
            textAlign: 'center',
        }}
    >
        <Container>
            <img
                src="/logo_nobg.png"
                alt="TwitchBot Logo"
                style={{width: '250px', height: '250px', marginBottom: '2rem'}}
            />
            <Typography
                component="h1"
                variant="h2"
                align="center"
                color="text.primary"
                gutterBottom
                sx={{ 
                    fontWeight: 'bold',
                    mb: 3
                }}
            >
                Сделайте стримы интерактивнее
            </Typography>
            <Typography 
                variant="h5" 
                align="center" 
                color="text.secondary" 
                paragraph
                sx={{ maxWidth: '800px', mx: 'auto' }}
            >
                Мощный бот для Twitch с функциями озвучки сообщений, автоматического перевода и множеством других возможностей для улучшения взаимодействия со зрителями
            </Typography>
            <Button
                variant="outlined"
                size="large"
                component={Link}
                className="landingButton"
                to="/sign-in"
                sx={{
                    borderColor: LIGHT_PURPLE,
                    color: LIGHT_PURPLE,
                    '&:hover': {
                        borderColor: LIGHT_PURPLE,
                        backgroundColor: LIGHT_PURPLE_HOVER,
                        transform: 'translateY(-2px)',
                        boxShadow: `0 5px 15px ${LIGHT_PURPLE_HOVER}`,
                    },
                    transition: 'all 0.3s ease',
                    fontSize: '1.2rem',
                    py: 1.5,
                    px: 4,
                    mt: 2
                }}
            >
                Начать использовать бесплатно
            </Button>
        </Container>
    </Box>
);

const Features = () => {
    const [hoveredFeature, setHoveredFeature] = useState(null);

    const handleMouseEnter = useCallback((index) => setHoveredFeature(index), []);
    const handleMouseLeave = useCallback(() => setHoveredFeature(null), []);

    return (
        <Box sx={{py: 8}}>
            <Typography variant="h3" align="center" gutterBottom>
                Возможности бота
            </Typography>
            <Grid 
                container 
                spacing={4} 
                justifyContent="center"
                sx={{ 
                    maxWidth: 'none',  // Убираем ограничение максимальной ширины
                    width: '100%',     // Устанавливаем полную ширину
                    mx: 0              // Убираем автоматические отступы
                }}
            >
                {[
                    {
                        name: "Озвучка сообщений",
                        description: "Озвучка сообщений в чате, или же через награду выделить сообщение," +
                            " огромный выбор голосов",
                        docs_link: "https://docs.jeetbot.cc/category/text-to-speech",
                    },
                    {
                        name: "Punto Switcher",
                        description: "Pf,skb cvtybnm hfcrkflre/ Ntgthm 'nj cjdctv yt ghj,ktvf!",
                        hoverText: "Забыли сменить раскладку? Теперь это совсем не проблема!",
                        docs_link: "https://docs.jeetbot.cc/",
                    },
                    {
                        name: "Конвертер валют",
                        description: "Бот может автоматически понимать, что в сообщении есть валюта, " +
                            "отличная от вашей валюты указанной в настройках канала, и переводить ее в вашу валюту. ",
                        docs_link: "https://docs.jeetbot.cc/currency-converter/automatic-conversion",
                    },
                    {
                        name: "Shazam",
                        description: "Устали от постоянных вопросов, что за трек играет сейчас? Бот " +
                            "может самостоятельно определять трек и исполнителя, и отвечать зрителям.",
                        docs_link: "https://docs.jeetbot.cc/",
                    },
                    {
                        name: "Переводчик",
                        description: "Бот может автоматически переводить сообщения в вашем чате",
                        docs_link: "https://docs.jeetbot.cc/",
                    },
                    {
                        name: "И многое другое",
                        description: "Еще больше подробностей о возможностях бота можно найти в нашей документации",
                        docs_link: "https://docs.jeetbot.cc/",
                    },
                ].map((feature, index) => (
                    <Grid item xs={12} sm={6} key={feature.name} sx={{mb: 2}}>
                        <Card
                            sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                bgcolor: '#1F2025',
                                '&:hover': {boxShadow: 6},
                                transition: 'all 0.3s ease-in-out',
                            }}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                        >
                            <CardHeader
                                title={feature.name}
                                titleTypographyProps={{variant: 'h6'}}
                                sx={{
                                    textAlign: 'left', 
                                    pb: 0,
                                    px: 3  // Добвляем горизонтальный padding для заголовка
                                }}
                            />
                            <CardContent sx={{
                                flexGrow: 1,
                                pt: 1,
                                pb: '16px !important',
                                px: 3,  // Увеличили горизонтальный padding для всего контента
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'relative',
                                minHeight: '150px',
                            }}>
                                <Box sx={{position: 'relative', flexGrow: 1}}>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            transition: 'opacity 0.3s ease-in-out',
                                            opacity: hoveredFeature === index && feature.hoverText ? 0 : 1,
                                            zIndex: 1,
                                        }}
                                    >
                                        {feature.description}
                                    </Typography>
                                    {feature.hoverText && (
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                transition: 'opacity 0.3s ease-in-out',
                                                opacity: hoveredFeature === index ? 1 : 0,
                                                zIndex: 2,
                                            }}
                                        >
                                            {feature.hoverText}
                                        </Typography>
                                    )}
                                </Box>
                                <Link 
                                    to={feature.docs_link}
                                    style={{
                                        textDecoration: 'none',
                                        marginTop: 'auto'
                                    }}
                                >
                                    <Button
                                        sx={{
                                            color: LIGHT_PURPLE,
                                            '&:hover': {
                                                backgroundColor: LIGHT_PURPLE_HOVER,
                                                transform: 'translateY(-2px)',
                                                boxShadow: `0 5px 15px ${LIGHT_PURPLE_HOVER}`,
                                            },
                                            transition: 'all 0.3s ease',
                                            textTransform: 'none',
                                            fontSize: '1rem',
                                            px: 2,
                                            ml: -2,
                                        }}
                                    >
                                        Подробнее
                                    </Button>
                                </Link>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

const ExampleVoices = () => (
    <Box sx={{py: 8}}>
        <Typography variant="h3" align="center" gutterBottom>
            Примеры голосов для озвучки
        </Typography>
        <Grid 
            container 
            spacing={4} 
            justifyContent="center"
            sx={{ 
                maxWidth: 'none',
                width: '100%',
                mx: 0
            }}
        >
            {[
                {name: "Скуф", audioSrc: "https://docs.jeetbot.cc/voice_examples/alt_for_skuf/an_alt_girl_dlc_sunboy_k.mp3"},
                {name: "Dota 2 Pudge", audioSrc: "https://docs.jeetbot.cc/voice_examples/dota2/dota2_pudge.mp3"},
                {name: "Stalker Sidor", audioSrc: "https://docs.jeetbot.cc/voice_examples/stalker_sidor.mp3"},
            ].map((voice) => (
                <Grid item xs={12} sm={6} md={4} key={voice.name}>
                    <Box sx={{textAlign: 'center'}}>
                        <Typography variant="h6" gutterBottom>
                            {voice.name}
                        </Typography>
                        <audio controls src={voice.audioSrc} style={{width: '100%', maxWidth: '300px'}}>
                            Ваш браузер не поддерживает аудио элемент.
                        </audio>
                    </Box>
                </Grid>
            ))}
        </Grid>
        <Box
            sx={{
                bgcolor: 'background.paper',
                pt: 8,
                pb: 6,
                textAlign: 'center',
            }}
        >
            <Button 
                variant="outlined" 
                size="large" 
                component={Link}
                to="https://docs.jeetbot.cc/text-to-speech/tts-voices"
                className="landingButton"
                sx={{
                    borderColor: LIGHT_PURPLE,
                    color: LIGHT_PURPLE,
                    '&:hover': {
                        borderColor: LIGHT_PURPLE,
                        backgroundColor: LIGHT_PURPLE_HOVER,
                        transform: 'translateY(-2px)',
                        boxShadow: `0 5px 15px ${LIGHT_PURPLE_HOVER}`,
                    },
                    transition: 'all 0.3s ease',
                    fontSize: '1.1rem',
                    py: 1,
                    px: 3,
                }}
            >
                Посмотреть все голоса
            </Button>
        </Box>
    </Box>
);

const SubscriptionPlans = () => (
    <Box sx={{py: 8}}>
        <Typography variant="h3" align="center" gutterBottom>
            Расширенные функции
        </Typography>
        <Grid 
            container 
            spacing={4} 
            justifyContent="center"
            sx={{ 
                maxWidth: 'none',
                width: '100%',
                mx: 0
            }}
        >
            {subscriptionPlans.map((plan) => (
                <Grid item xs={12} sm={6} md={4} key={plan.name}>
                    <Card sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        bgcolor: '#1A1A1A',
                        '&:hover': {boxShadow: 6},
                        border: 1,
                        borderColor: '#333333',
                    }}>
                        <CardHeader
                            title={plan.name}
                            subheader={
                                <Typography variant="h4" sx={{ color: LIGHT_PURPLE }} fontWeight="bold">
                                    {plan.price}
                                </Typography>
                            }
                            titleTypographyProps={{
                                variant: 'h5', 
                                color: '#FFFFFF',
                                align: 'left'
                            }}
                            subheaderTypographyProps={{
                                align: 'left'
                            }}
                            sx={{
                                bgcolor: '#2A2A2A',
                                color: '#FFFFFF',
                                pb: 3,
                                px: 3,
                                '.MuiCardHeader-content': {
                                    textAlign: 'left'
                                }
                            }}
                        />
                        <CardContent sx={{flexGrow: 1, pt: 2}}>
                            <List sx={{ 
                                p: 0,
                                '& .MuiListItem-root': { 
                                    px: 0,
                                    alignItems: 'flex-start'
                                }
                            }}>
                                {plan.features.map((feature) => (
                                    <ListItem key={feature}>
                                        <ListItemIcon sx={{ 
                                            minWidth: 36,
                                            pt: '3px'
                                        }}>
                                            <CheckCircleIcon sx={{
                                                color: LIGHT_PURPLE,
                                                fontSize: 20
                                            }}/>
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={feature} 
                                            sx={{
                                                color: '#E0E0E0',
                                                m: 0,
                                                '& .MuiTypography-root': {
                                                    fontSize: '0.95rem',
                                                    lineHeight: 1.5
                                                }
                                            }}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </CardContent>
                        <Box sx={{p: 2, textAlign: 'center'}}>
                            <Button
                                variant="outlined" 
                                component={Link} 
                                to="/sign-in"
                                className="landingButton"
                                size="large"
                                sx={{
                                    borderColor: LIGHT_PURPLE,
                                    color: LIGHT_PURPLE,
                                    '&:hover': {
                                        borderColor: LIGHT_PURPLE,
                                        backgroundColor: LIGHT_PURPLE_HOVER,
                                        transform: 'translateY(-2px)',
                                        boxShadow: `0 5px 15px ${LIGHT_PURPLE_HOVER}`,
                                    },
                                    transition: 'all 0.3s ease',
                                    width: '80%',
                                    minWidth: '200px',
                                    py: 1,
                                }}
                            >
                                Попробовать
                            </Button>
                        </Box>
                    </Card>
                </Grid>
            ))}
        </Grid>
    </Box>
);

const FAQ = () => (
    <Box sx={{py: 8}}>
        <Typography variant="h3" align="center" gutterBottom>
            Часто задаваемые вопросы
        </Typography>
        {[
            {
                question: "Как добавить бота себе на канал?",
                answer: "Достаточно просто авторизаваться через твитч, и настроить бота в нашей панели управления"
            },
            {
                question: "Как выбрать голос?",
                answer: "Стример выбирает стандартный голос для всех зрителей, а зритель может сменить голос сам с помощью команды"
            },
            {
                question: "Что делать если бот перестал отвечать?",
                answer: "Проверьте, не забанили ли вы бота, а так же авторизуйтесь в панели управления, и если бот активен, попробуйте деактивировать его и активировать еще раз"
            },
        ].map((item, index) => (
            <Accordion key={index}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography variant="h6">{item.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>{item.answer}</Typography>
                </AccordionDetails>
            </Accordion>
        ))}
    </Box>
);

export default function HomePage() {
    ym_hit('/');

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Box sx={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
                <AppBar position="fixed"> {/* Изменили с "relative" на "fixed" */}
                    <Toolbar>
                        <img
                            src="/logo_nobg.png"
                            alt="logo"
                            style={{width: '40px', height: '40px'}}
                        />
                        <Typography sx={{ml: 2, flexGrow: 1}} variant="h6" color="inherit" noWrap>
                            JeetBot
                        </Typography>
                        <TelegramButton />
                        <Button 
                            variant="outlined" 
                            component={Link} 
                            to="/sign-in"
                            className="landingButton"
                            startIcon={<img src="/twitch_icon.png" alt="Twitch" width="24" height="24"/>}
                            sx={{
                                borderColor: '#B975FF', // Используем тот же светлый фиолетовый
                                color: '#B975FF',
                                '&:hover': {
                                    borderColor: '#B975FF',
                                    backgroundColor: 'rgba(185, 117, 255, 0.1)',
                                    transform: 'translateY(-2px)',
                                    boxShadow: '0 5px 15px rgba(185, 117, 255, 0.2)',
                                },
                                transition: 'all 0.3s ease',
                            }}
                        >
                            Login
                        </Button>
                    </Toolbar>
                </AppBar>
                <Toolbar /> {/* Добавляем пустой Toolbar для компенсации фиксированого AppBar */}
                <main style={{flex: 1}}>
                    <Hero/>
                    <Container 
                        maxWidth="lg"  // Вернули обратно на "lg"
                        sx={{
                            px: { xs: 3, sm: 4, md: 5 }  // Стандартные отступы
                        }}
                    >
                        <Features/>
                        <ExampleVoices/>
                        <SubscriptionPlans/>
                        <FAQ/>
                    </Container>
                </main>
                <Box sx={{bgcolor: 'background.paper', p: 6}} component="footer">
                    <MyCopyright/>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

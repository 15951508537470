import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {Breadcrumbs} from "@mui/material";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import {Link as RouterLink} from "react-router-dom";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {DataGrid} from "@mui/x-data-grid";
import {GridColDef} from "@mui/x-data-grid";
import Requester from "../../../utils/requester";
import {useEffect, useReducer, useState} from "react";



const columns: GridColDef[] = [
    {
        field: 'command_name',
        headerName: 'Command Name',
        width: 150,
        editable: true,
    },
    {
        field: 'command_prefix',
        headerName: 'Command Prefix',
        width: 150,
        editable: true,
    },
    {
        field: 'command_class_name',
        headerName: 'Command Type',
        type: 'number',
        width: 110,
        editable: true,
    },
    {
        field: 'is_active',
        headerName: 'Active',
        description: '',
        sortable: false,
        width: 160,
    },
];


function CommandsListGrid(props) {
    return (
        <Box sx={{ height: 700, width: '100%' }}>
            <DataGrid
                rows={props.rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                pageSizeOptions={[5]}
                checkboxSelection
                disableRowSelectionOnClick
            />
        </Box>
    );
};

export default function CommandList() {
    const [rows, setRows] = useState([]);
    useEffect(() => {
        Requester.get("commands/list/").then(async (response) => {
            if (response.status !== 200) {
                return;
            }
            let data = await response.json()
            setRows(data);
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    return (
        <>
            <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
                <Grid container>
                    <Grid item xs={12}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" to={"/dashboard"} component={RouterLink}>
                                Home
                            </Link>
                            <Typography color="text.primary">Commands</Typography>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            </Container>

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <CommandsListGrid rows={rows}/>
                </Grid>
            </Grid>

        </>
    );
}
import {lightBlue, red} from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#A855F7', // Более яркий фиолетовый
    },
    secondary: {
      main: '#00B2FF', // Более яркий синий
    },
    error: {
      main: red.A400,
    },
    action: {
      active: lightBlue[200],
      activeOpacity: 1,
      hover: '#271a3a',
      hoverOpacity: 0.1,
      focus: lightBlue[600],
      focusOpacity: 1,
      selected: lightBlue[300],
      selectedOpacity: 1
    },
  },
});

export default theme;

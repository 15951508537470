import * as React from "react";
import {useEffect} from "react";
import Requester from "../utils/requester";
import '../index.css';
import {TWITCH_REDIRECT_URI} from "../constants";
import Typography from "@mui/material/Typography";
import {CircularProgress} from "@mui/material";
import Grid from "@mui/material/Grid";
import {redirect_with_error} from "../utils/on_error";
import {defer} from "react-router-dom";

export const twitchProcessAccess = async (params) => {
    // get params from url
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    const scope = urlParams.get('scope');

    Requester.post('twitch/process_twitch_access_code/', {
        code: code,
        state: state,
        scope: scope,
        redirect_url: TWITCH_REDIRECT_URI,
    }).then((response, status) => {
        if (response.status !== 200) {
            console.log(response)
            redirect_with_error('/sign-in');
            return
        }
        response.json().then(data => {
            if ([400, 401].includes(response.status)) {
                console.log(data)
                return
            }
            localStorage.setItem('token', data.access);
            localStorage.setItem('refreshToken', data.refresh);
            window.location.href = '/dashboard';
        })
    }).catch((error) => {
        console.log(error);
        redirect_with_error('/sign-in');
        return;
    });
    return defer({})
}


export default function TwitchProcessAccess() {
    return (
        <>
            <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}
                  sx={{minHeight: '100vh'}}>
                <Grid item>
                    <Typography variant="h4" component="div" gutterBottom>
                        Processing Twitch Access
                    </Typography>
                </Grid>
                <Grid item>
                    <CircularProgress/>
                </Grid>
            </Grid>
        </>
    );
}

export const subscriptionPlans = [
  {
    name: "Решил попробовать",
    price: "300р/месяц",
    features: ["300 сообщений для озвучки", "AI Style для озвучки"]
  },
  {
    name: "Распробовал",
    price: "1200р/месяц",
    features: ["1600 сообщений для озвучки", "AI Style для озвучки"]
  },
  {
    name: "Мастер",
    price: "5000р/месяц",
    features: [
      "Никаких лимитов на озвучку",
      "AI Style для озвучки",
      "Бот с кастомным ником",
      "Выделенный сервер для стабильной и быстрой работы бота",
      "Возможность разместить бота у других стримеров"
    ]
  },
];
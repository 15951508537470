import * as React from 'react';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {
    Alert,
    Autocomplete,
    Breadcrumbs,
    Checkbox,
    FormControlLabel,
    InputLabel,
    MenuItem,
    NativeSelect,
    Select,
    Tooltip
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { defer, Link as RouterLink, useLoaderData } from "react-router-dom";
import Container from "@mui/material/Container";
import Requester from "../../../utils/requester";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import {ym_hit} from "../../../utils/yandex";
import Switch from '@mui/material/Switch';
import InfoIcon from '@mui/icons-material/Info';

const API_URL = "channel/tts_settings/"

const getTtsSettings = async () => {
    const response = await Requester.get(API_URL)
    const response_data = await response.json()
    return response_data
}

export const ttsSettingsLoader = async () => {
    const tts_settings = await getTtsSettings()
    return defer(tts_settings)
}

function get_me_voice(voices, current_voice){
    let me_voice = voices.find(voice => voice[0] === current_voice)
    if (me_voice === undefined){
        return voices[0]
    }
    return me_voice

}
export default function TtsSettings() {
    const tts_settings = useLoaderData();

    const [ttsSettings, setTtsSettings] = React.useState(tts_settings);
    const [ selectState, setSelectState ] = React.useState({
        current_channel_default_voice: tts_settings.current_channel_default_voice,
        tts_additional_voice_for_me_message: tts_settings.tts_additional_voice_for_me_message
    })
    const [state, setState] = React.useState({
        successSaved: false,
        loading: false
    })
    React.useEffect(() => {
        setTtsSettings(tts_settings)
    }, [tts_settings])
    const [ttsEnabled, setTtsEnabled] = React.useState(ttsSettings.tts_enabled);
    const [handleTtsEnabledChange, setHandleTtsEnabledChange] = React.useState(() => () => {});

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (state.loading) {
            return
        }
        setState({ ...state, loading: true, successSaved: false})


        let dataToSend = {
            tts_enabled: Boolean(data.get('tts_enabled')),
            filter_bad_words_tts: Boolean(data.get('filter_bad_words_tts')),
            only_ru_tts: Boolean(data.get('only_ru_tts')),
            tts_clean_names: Boolean(data.get('tts_clean_names')),
            translate_tts: Boolean(data.get('translate_tts')),
            current_channel_default_voice: selectState.current_channel_default_voice,
            tts_additional_voice_for_me_message: selectState.tts_additional_voice_for_me_message
        }
        try {
            Requester.post(API_URL, dataToSend)
                .then(response => {
                if (response.status === 200) {
                    getTtsSettings().then(data => {
                        setTtsSettings(data)
                        setState({ ...state, successSaved: true })
                    })
                }
                if (response.status === 400) {
                        response.json().then(data => {
                            console.log(data)
                        })
                    }
            })
        } catch (e) {
            console.log(e)
        } finally {
            setState({ ...state, loading: false })
        }

    };
    ym_hit('/dashboard/tts-settings')
    return (
        <>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" to={"/dashboard"} component={RouterLink}>
                                Home
                            </Link>
                            <Typography color="text.primary">Озвучка сообщений</Typography>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                <Grid container spacing={3} mt={2}>
                                    <Grid item xs={6}>
                                        <FormControlLabel
                                            control={<Checkbox color="secondary" name="tts_enabled"
                                                value="yes"
                                                defaultChecked={ttsSettings.tts_enabled}
                                            />}
                                            label="Включить озвучку сообщений в чате"
                                        />
                                    </Grid>
                                    
                                    {!ttsSettings.tts_enabled && (
                                        <Grid item xs={12} sx={{ mt: -1 }}>
                                            <Box
                                                sx={{
                                                    p: 2.5,
                                                    bgcolor: 'rgba(145, 70, 255, 0.08)',
                                                    borderRadius: 2,
                                                    border: '1px solid rgba(145, 70, 255, 0.3)',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 2,
                                                    maxWidth: '800px'
                                                }}
                                            >
                                                <InfoIcon 
                                                    sx={{ 
                                                        color: '#B975FF',
                                                        fontSize: 24 
                                                    }} 
                                                />
                                                <Typography 
                                                    sx={{ 
                                                        color: '#E0E0E0',
                                                        fontSize: '0.95rem',
                                                        lineHeight: 1.5
                                                    }}
                                                >
                                                    Включите озвучку сообщений и сохраните изменения чтобы получить ссылку для OBS
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    )}

                                    <Grid item xs={6}>
                                        <FormControlLabel
                                            control={<Checkbox color="secondary" name="filter_bad_words_tts"
                                                value="yes"
                                                defaultChecked={ttsSettings.filter_bad_words_tts}
                                            />}
                                            label="Фильтровать мат и банворды"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Tooltip
                                            title="Автоматически конвертируем весь текст в русский транслитом и используем русские голоса"
                                            placement="top">
                                            <FormControlLabel
                                                control={<Checkbox color="secondary" name="only_ru_tts"
                                                    value="yes"
                                                    defaultChecked={ttsSettings.only_ru_tts}
                                                />}
                                                label="Всегда озвучивать на русском"
                                            />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Tooltip
                                            title="Бот будет автоматически удалять упоминания или ники пользователей из чата"
                                            placement="top">
                                            <FormControlLabel
                                                control={<Checkbox color="secondary" name="tts_clean_names"
                                                    value="yes"
                                                    defaultChecked={ttsSettings.tts_clean_names}
                                                />}
                                                label="Вырезать имена"
                                            />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Tooltip
                                            title="Если включено, бот сообщения на других языках будут автоматически переведены на русский, и озвучены русским голосом"
                                            placement="top">
                                            <FormControlLabel
                                                control={<Checkbox color="secondary" name="translate_tts"
                                                    value="yes"
                                                    defaultChecked={ttsSettings.translate_tts}
                                                />}
                                                label="Переводить сообщения"
                                            />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={6}>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                            Стандартный голос озвучки
                                        </InputLabel>
                                        <Tooltip
                                            title="Будет использован для всех зрителей, кто не сменил голос"
                                            placement="top">
                                            <Autocomplete
                                                disablePortal
                                                id="current_channel_default_voice_select"
                                                options={ttsSettings.allowed_default_voices.map((voice) => (
                                                    {label: voice, id: voice}
                                                ))}
                                                sx={{ width: '100%' }}  // Изменено с 300 на '100%'
                                                onChange={(event, newValue) => {
                                                    setSelectState({
                                                        ...selectState,
                                                        current_channel_default_voice: newValue.id
                                                    })
                                                }}

                                                defaultValue={{label: ttsSettings.current_channel_default_voice, id: ttsSettings.current_channel_default_voice}}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                            Голос для озвучки /me
                                        </InputLabel>
                                        <Tooltip
                                            title="Включает возможность озвучки сообщения /me выбранным голосом"
                                            placement="top">
                                            <Autocomplete
                                                disablePortal
                                                id="tts_additional_voice_for_me_message_select"
                                                options={ttsSettings.tts_additional_voice_for_me_message_options.map((voice) => (
                                                    {label: voice[1], id: voice[0]}
                                                ))}
                                                sx={{ width: '100%' }}  // Изменено с 300 на '100%'
                                                onChange={(event, newValue) => {
                                                    setSelectState({
                                                        ...selectState,
                                                        tts_additional_voice_for_me_message: newValue.id
                                                    })
                                                }}

                                                defaultValue={
                                                    {
                                                        label: get_me_voice(ttsSettings.tts_additional_voice_for_me_message_options, ttsSettings.tts_additional_voice_for_me_message)[1],
                                                        id: get_me_voice(ttsSettings.tts_additional_voice_for_me_message_options, ttsSettings.tts_additional_voice_for_me_message)[0]
                                                    }
                                                }
                                                renderInput={(params) => <TextField {...params} />}
                                            />

                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                {state.successSaved && (
                                    <Alert 
                                        sx={{ mt: 2 }}  // Добавляем отступ сверху
                                        onClose={() => {
                                        setState(
                                            { ...state, successSaved: false }
                                        )
                                    }} severity="success">Настройки успешно сохранены</Alert>
                                )}
                                {/*//save button*/}
                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="outlined"  // Изменили с "contained" на "outlined"
                                        sx={{ 
                                            mt: 3, 
                                            mb: 2,
                                            borderColor: '#B975FF',
                                            color: '#B975FF',
                                            fontSize: '1.1rem',
                                            py: 1,
                                            '&:hover': {
                                                borderColor: '#B975FF',
                                                backgroundColor: 'rgba(185, 117, 255, 0.2)',
                                                transform: 'translateY(-2px)',
                                                boxShadow: '0 5px 15px rgba(185, 117, 255, 0.3)',
                                            },
                                            transition: 'all 0.3s ease',
                                        }}
                                    >
                                        Сохранить
                                    </Button>
                                </Grid>
                                {ttsSettings.tts_enabled && (
                                    <>
                                        <Grid item xs={12} mt={4}>
                                            <TextField
                                                id="outlined-basic"
                                                label="Ссылка для озвучки"
                                                variant="outlined"
                                                fullWidth
                                                value={ttsSettings.tts_link}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} mt={4}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    gap: 2,
                                                    p: 3,
                                                    bgcolor: 'rgba(145, 70, 255, 0.08)',
                                                    borderRadius: 2,
                                                    border: '1px solid rgba(145, 70, 255, 0.3)',
                                                }}
                                            >
                                                <Link 
                                                    to="https://docs.jeetbot.cc/text-to-speech/basic-usage"
                                                    component={RouterLink}
                                                    sx={{
                                                        color: 'inherit',
                                                        textDecoration: 'none',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        gap: 1,
                                                        '&:hover': {
                                                            '& .MuiSvgIcon-root': {
                                                                color: '#B975FF',
                                                                transform: 'translateY(-2px)',
                                                            },
                                                            '& .MuiTypography-root': {
                                                                color: '#B975FF',
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <MenuBookIcon 
                                                        sx={{ 
                                                            fontSize: 40,
                                                            color: '#9146FF',
                                                            transition: 'all 0.3s ease',
                                                        }} 
                                                    />
                                                    <Typography
                                                        sx={{
                                                            transition: 'all 0.3s ease',
                                                            fontSize: '1.1rem',
                                                        }}
                                                    >
                                                        Подробнее о настройке в документации
                                                    </Typography>
                                                </Link>
                                            </Box>
                                        </Grid>
                                    </>
                                )}
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
        ;
}

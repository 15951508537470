import {redirect} from "react-router-dom";
import jwt_decode from "jwt-decode";

function get_local_storage(key) {
    let value = localStorage.getItem(key);
    if (value === 'undefined') {
        return null;
    }
    return value;
}

export default class Requester {
    static BASE_URL = process.env.REACT_APP_API_URL || `http://127.0.0.1:8000/dashboard/api/`;

    static getToken() {
        return get_local_storage("token");
    }

    static getRefreshToken() {
        return get_local_storage("refreshToken");
    }

    static redirectToSignIn() {
        redirect("/sign-in");
    }

    static async refreshToken() {
        if (!this.getRefreshToken()) {
            return Promise.resolve(false);
        }
        return fetch(`${this.BASE_URL}token/refresh/`, {
                method: "POST",
                body: JSON.stringify({refresh: this.getRefreshToken()}),
                headers: {
                    "Content-type": "application/json",
                }
            }
        ).then(async response => {
            if (response.status !== 200) {
                localStorage.removeItem("token");
                localStorage.removeItem("refreshToken");
                return false;
            }
            let data = await response.json()
            localStorage.setItem("token", data.access);
            return true;
        }).catch(error => {
            localStorage.removeItem("token");
            localStorage.removeItem("refreshToken");
            console.log(error)
            return false;
        })
    }

    static validateToken() {
        if (!this.getToken()) {
            if (this.getRefreshToken()) {
                return this.refreshToken().then(result => {
                    return result;
                })
            }
            return Promise.resolve(false);
        }
        let decoded_token = jwt_decode(this.getToken());
        if (decoded_token.exp < Date.now() / 1000) {
            return this.refreshToken().then(result => {
                return result;
            })
        }
        // return fetch(`${this.BASE_URL}token/verify/`, {
        //         method: "POST",
        //         body: JSON.stringify({token: this.getToken()}),
        //         headers: {
        //             "Content-type": "application/json",
        //         }
        //     }
        // ).then(response => {
        //     if (response.status === 401) {
        //         return this.refreshToken().then(result => {
        //             return result;
        //         })
        //     }
        //     return response.status === 200;
        // }).catch(error => {
        //     localStorage.removeItem("token");
        //     console.log(error)
        //     return false;
        // })
        return Promise.resolve(true);
    }

    static async post(url, data) {
        let response = await fetch(`${this.BASE_URL}${url}`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${this.getToken()}`,
            }
        })
        if (response.status === 401) {
            return this.refreshToken().then(result => {
                if (result) {
                    return this.post(url, data);
                }
                return response;
            })
        }
        if (response.status === 403) {
            return response.json().then(data => {
                console.log(data)
                if (data.code === "token_not_valid") {
                    return this.refreshToken().then(result => {
                        if (result) {
                            return this.post(url, data);
                        }
                        return response;
                    })
                }
                return response;
            })
        }

        return response;
    }

    static async get(url) {
        let response = await fetch(`${this.BASE_URL}${url}`, {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`
                }
            }
        )
        if (response.status === 401) {
            return this.refreshToken().then(result => {
                if (result) {
                    return this.get(url);
                }
                return response;
            })
        }
        if (response.status === 403) {
            return response.json().then(data => {
                console.log(data)
                if (data.code === "token_not_valid") {
                    return this.refreshToken().then(result => {
                        if (result) {
                            return this.get(url);
                        }
                        return response;
                    })
                }
                return response;
            })
        }

        return response;
    }

}
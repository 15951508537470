import React, { useState } from "react";
import { useUser } from '../../../context/UserContext';
import { Box, Button, Card, CardContent, Typography, Chip, Modal, Grid, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StarIcon from '@mui/icons-material/Star';
import { subscriptionPlans } from "../../../data/subscriptionPlans";

const getSubscriptionName = (level) => {
    if (level.includes('Решил попробовать')) {
        return 'Решил попробовать';
    }
    if (level.includes('Не распробовал')){
        return 'Не распробовал';
    }
    if (level.includes('Распробовал')) {
        return 'Распробовал';
    }
    if (level.includes('Мастер')) {
        return 'Мастер';
    }
    return 'default';
};

const SubscriptionInfo = ({ currentSubscription, onUpgrade }) => {
    const getSubscriptionColor = (level) => {
        if (level.includes('Решил попробовать') || level.includes('Не распробовал')) {
            return 'primary';
        }
        if (level.includes('Распробовал')) {
            return 'warning';
        }
        if (level.includes('Мастер')) {
            return 'error';
        }
        return 'default';
    };

    return (
        <Card sx={{ p: 2, mb: 2, position: 'relative', overflow: 'visible' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Box sx={{ flex: 1 }}>
                    <Typography variant="h6" gutterBottom>TTS Limit</Typography>
                    <Typography variant="h4">
                        {currentSubscription.left_tts_limit ?? 'N/A'}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        сообщений осталось сегодня
                    </Typography>
                </Box>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-end', position: 'absolute', top: -15, right: 16}}>
                    <Box sx={{ mb: 2 }}>
                        <Chip
                            label={getSubscriptionName(currentSubscription.name) || 'Free'}
                            color={getSubscriptionColor(currentSubscription.name)}
                            icon={<StarIcon />}
                        />
                    </Box>
                    <Button variant="contained" onClick={onUpgrade} sx={{ mt: 1 }}>
                        {currentSubscription.default ? 'Купить подписку' : 'Повысить подписку'}
                    </Button>
                </Box>
            </Box>
        </Card>
    );
};
const SubscriptionModal = ({open, onClose, currentSubscription}) => {

    return (        
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '90%',
                maxWidth: 1200,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                maxHeight: '90vh',
                overflow: 'auto'
            }}>
                <Typography variant="h4" component="h2" gutterBottom>Upgrade Subscription</Typography>
                <Grid container spacing={3} justifyContent="center">
                    {subscriptionPlans.map((plan, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between'
                            }}>
                                <CardContent>
                                    <Typography variant="h5" component="div" gutterBottom>{plan.name}</Typography>
                                    <Typography variant="h6" color="primary" gutterBottom>{plan.price}</Typography>
                                    <List>
                                        {plan.features.map((feature, featureIndex) => (
                                            <ListItem key={featureIndex} dense>
                                                <ListItemIcon>
                                                    <CheckCircleIcon color="primary"/>
                                                </ListItemIcon>
                                                <ListItemText primary={feature}/>
                                            </ListItem>
                                        ))}
                                    </List>
                                </CardContent>
                                <CardContent>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        disabled={!currentSubscription.default && 
                                                 getSubscriptionName(currentSubscription.name) === getSubscriptionName(plan.name)}
                                        onClick={() => alert("Мы в данный момент работаем над платежной системой, в данный момент вы можете купить подписку на бусти https://jeetbot.cc/boosty")}
                                    >
                                        {!currentSubscription.default && 
                                         getSubscriptionName(currentSubscription.name) === getSubscriptionName(plan.name)
                                            ? 'Текущий тариф' 
                                            : `Изменить на ${plan.name}`}
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <Button onClick={onClose} sx={{mt: 3}}>Close</Button>
            </Box>
        </Modal>
    );
};

export default function DashIndex() {
    const { user } = useUser();
    const [modalOpen, setModalOpen] = useState(false);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    if (!user) {
        return <Typography>Подгружаем данные...</Typography>;
    }

    return (
        <Box sx={{ maxWidth: 800, margin: 'auto', pt: 4 }}>
            <Typography variant="h4" gutterBottom>Welcome, {user.name}!</Typography>
            <SubscriptionInfo
                currentSubscription={user.current_subscription}
                onUpgrade={handleOpenModal}
            />
            <SubscriptionModal 
                open={modalOpen} 
                onClose={() => setModalOpen(false)}
                currentSubscription={user.current_subscription}
            />
        </Box>
    );
}

import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { Link } from 'react-router-dom';
import { Construction } from '@mui/icons-material';

const NotImplementedPage = () => {
  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '80vh',
          textAlign: 'center',
          gap: 3
        }}
      >
        <Construction 
          sx={{ 
            fontSize: '64px',
            color: '#9146FF',
            animation: 'bounce 2s infinite'
          }} 
        />
        
        <Typography 
          variant="h2" 
          sx={{
            fontWeight: 'bold',
            color: '#E0E0E0',
            mb: 2
          }}
        >
          В разработке
        </Typography>
        
        <Typography 
          variant="h5" 
          sx={{
            color: '#999',
            maxWidth: '600px',
            mb: 4
          }}
        >
          Эта страница находится в разработке. Следите за обновлениями в нашем телеграм канале.
        </Typography>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            component={Link}
            to="/"
            variant="contained"
            sx={{
              backgroundColor: '#9146FF',
              '&:hover': {
                backgroundColor: '#7B31ED'
              }
            }}
          >
            На главную
          </Button>
          
          <Button
            component="a"
            href="https://jeetbot.cc/tg"
            target="_blank"
            variant="outlined"
            sx={{
              borderColor: '#9146FF',
              color: '#9146FF',
              '&:hover': {
                borderColor: '#7B31ED',
                color: '#7B31ED',
                backgroundColor: 'rgba(145, 70, 255, 0.1)'
              }
            }}
          >
            Telegram канал
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default NotImplementedPage;

import * as React from 'react';
import {createContext} from 'react';
import * as ReactDOM from 'react-dom/client';
import HomePage from "./routes/homePage";
import {
    createBrowserRouter, createRoutesFromChildren,
    createRoutesFromElements, matchRoutes,
    Route,
    RouterProvider,
    Routes,
    useLocation, useNavigationType
} from "react-router-dom";
import ErrorPage from "./routes/error-page";
import SignIn from "./routes/signin";
import NotImplementedPage from "./routes/not-implemented";
import Dashboard from "./routes/dashboard/dashboard";
import CommandList from "./routes/dashboard/pages/command-list";
import DashIndex from "./routes/dashboard/pages/dash-index";
import AuthRequired from "./modules/auth/AuthRequired";
import ChannelForm, {channelFormLoader} from "./routes/dashboard/pages/channel-form";
import RootLayout from "./routes/dashboard/pages/root-layout";
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";
import TwitchSignIn, {twitchSignInLoader} from "./routes/twitch-signin";
import TwitchProcessAccess, {twitchProcessAccess} from "./routes/twitch-process-access";
import {BrowserTracing} from "@sentry/react";
import * as Sentry from "@sentry/react";
import TtsSettings, {ttsSettingsLoader} from "./routes/dashboard/pages/tts-settings";
import TimerList, {timersLoader} from "./routes/dashboard/pages/timer-list";
import { UserProvider } from './context/UserContext';

export const LevelContext = createContext(1);

Sentry.init({
    dsn: "https://b1f67e989e194fd1ae9b475c88aba5b6@sentry.alexue4.dev/10",
    integrations: [
        new Sentry.BrowserTracing(
            {
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                ),
            }
        ),
        new Sentry.BrowserProfilingIntegration(),
        new Sentry.Replay()
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
});

const Root = () => {
  return (
    <UserProvider>
      <DevSupport 
        ComponentPreviews={ComponentPreviews}
        useInitialHook={useInitial}
      >
        <RootLayout />
      </DevSupport>
    </UserProvider>
  );
};

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            element={<Root />}
            path="/"
            errorElement={<ErrorPage/>}
        >
            <Route
                element={<HomePage/>}
                path="/"
                errorElement={<ErrorPage/>}
            />
            <Route
                element={<SignIn/>}
                path="sign-in"
                errorElement={<ErrorPage/>}
            />,
            <Route
                element={<TwitchSignIn/>}
                loader={twitchSignInLoader}
                path="twitch-sign-in"
                errorElement={<ErrorPage/>}
            />,
            <Route
                element={<TwitchProcessAccess/>}
                loader={twitchProcessAccess}
                path="twitch-auth-callback"
                errorElement={<ErrorPage/>}
            />,
            <Route
                element={
                    <AuthRequired/>
                }
            >
                <Route
                    element={<Dashboard/>}
                    path="dashboard"
                    errorElement={<ErrorPage/>}
                >
                    <Route
                        element={<DashIndex/>}
                        path=""
                        errorElement={<ErrorPage/>}
                    />
                    <Route
                        element={<CommandList/>}
                        path="commands"
                        errorElement={<ErrorPage/>}
                    />
                    <Route
                        element={<ChannelForm/>}
                        loader={channelFormLoader}
                        path="channel-edit"
                        errorElement={<ErrorPage/>}
                    />
                    <Route
                        element={<TtsSettings/>}
                        loader={ttsSettingsLoader}
                        path="tts-settings"
                        errorElement={<ErrorPage/>}
                    />
                    <Route
                        element={<TimerList/>}
                        loader={timersLoader}
                        path="timers/list"
                        errorElement={<ErrorPage/>}
                    />
                </Route>
            </Route>
            <Route
                element={<NotImplementedPage/>}
                path="*"
                errorElement={<ErrorPage/>}
            />
        </Route>
    )
);

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

import React from 'react';
import { Button } from '@mui/material';
import { FaTelegram } from 'react-icons/fa';

const TelegramButton = () => {
  return (
    <Button
      variant="outlined"
      href="https://jeetbot.cc/tg"
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        borderColor: '#00D1FF', // Еще более яркий синий
        color: '#00D1FF',      // Еще более яркий синий
        mr: 2,
        '&:hover': {
          borderColor: '#00D1FF',
          backgroundColor: 'rgba(0, 209, 255, 0.1)', // Обновленный цвет для hover
          transform: 'translateY(-2px)',
          boxShadow: '0 5px 15px rgba(0, 209, 255, 0.2)',
        },
        transition: 'all 0.3s ease',
        display: 'flex',
        alignItems: 'center',
        gap: 1
      }}
      startIcon={<FaTelegram />}
    >
      Наши новости
    </Button>
  );
};

export default TelegramButton;

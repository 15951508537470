const YANDEX_ID = 96101414


export function ym_hit(url) {
    setTimeout(() => {
        try {
            window.ym(YANDEX_ID, 'hit', url)
        } catch (e) {
            console.error(e)
        }
    })
}
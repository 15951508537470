import {ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import * as React from "react";
import {useState} from "react";
import MyCopyright from "../modules/common/copyright";
import theme from "../theme";
import Requester from "../utils/requester";
import {Alert, AlertTitle} from "@mui/material";
import '../index.css';
import twitch_icon from "../images/twitch.svg";
import {defer, NavLink} from "react-router-dom";
import {ym_hit} from "../utils/yandex";


export default function SignIn() {
    const urlParams = new URLSearchParams(window.location.search);
    const error = urlParams.get('error');
    const [state, setState] = useState({
        usernameError: null,
        passwordError: null,
        errorDetail: error,
    })

    Requester.validateToken().then(result => {
        if (result) {
            window.location.href = '/dashboard';
            return;
        }
    }).catch(error => {
        console.log(error)
    })

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        Requester.post('token/', {
            username: formData.get('username'),
            password: formData.get('password'),
        }).then((response, status) => {
            response.json().then(data => {
                if ([400, 401].includes(response.status)) {
                    setState({
                        usernameError: data.username ? data.username[0] : null,
                        passwordError: data.password ? data.password[0] : null,
                        errorDetail: data.detail ? data.detail : null,
                    })
                    return
                }
                localStorage.setItem('token', data.access);
                localStorage.setItem('refreshToken', data.refresh);
                window.location.href = '/dashboard';
            })
        }).catch((error) => {
            console.log(error);
            setState({
                usernameError: null,
                passwordError: null,
                errorDetail: error.message,
            })
        });
    };
    ym_hit('/sign-in');
    return (
        <>
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline/>
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                            {state.errorDetail !== null && (
                                <Alert severity="error">
                                    <AlertTitle>Error</AlertTitle>
                                    {state.errorDetail}
                                </Alert>
                            )}
                            <Link to={"/twitch-sign-in"} component={NavLink} color="inherit" underline="hover">
                                <Button
                                    fullWidth
                                    variant="contained"
                                    sx={{mb: 2}}
                                    startIcon={<img src={twitch_icon} alt="twitch" className="twitch-icon"/>}
                                >
                                    ВОЙТИ ЧЕРЕЗ TWITCH
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                    <MyCopyright sx={{mt: 8, mb: 4}}/>
                </Container>
            </ThemeProvider>
        </>
    );

}